import { useTranslation } from 'react-i18next';
import imcs1j from '../assets/images/cost1j.jpg';
import imcs2j from '../assets/images/cost2j.jpg';
import imcs1e from '../assets/images/cost1e.jpg';
import imcs2e from '../assets/images/cost2e.jpg';
import imgude from '../assets/images/guide.png';
import imback from '../assets/icons/back.png';

export function F_cost({ onback }) {
    const { t, i18n } = useTranslation();
    const backbt = () => {
        if (onback) {
            onback();
        }
    };

    return (
        <div className='position-fixed start-0 top-0 bottom-0 end-0 pb-5 vh-100 bg-white overflow-auto' style={{ zIndex: 1 }}>
            <div className="text-center mt-5 pt-2 d-flex bg-light">
                <div className='d-flex align-items-center' onClick={backbt} style={{ height: '35px', marginTop: '-5px' }}>
                    <img className='h-100 ps-2' src={imback} />
                    <div className='ps-1'>
                        戻る
                    </div>
                </div>
                <div className='flex-grow-1 fs-5 fw-blod'>
                    {t('レンタル料金')}
                </div>
            </div>
            <div className='px-1 w-100 bg-white overflow-auto'>
                {
                    i18n.language == 'jp' ?
                        <>
                            <img className='w-100' src={imcs1j} />
                            <img className='w-100' src={imcs2j} />
                        </> :
                        <>
                            <img className='w-100' src={imcs1e} />
                            <img className='w-100' src={imcs2e} />
                        </>
                }

            </div>
        </div>
    )
};

export function Fguide({ onback }) {
    const { t, i18n } = useTranslation();
    const backbt = () => {
        if (onback) {
            onback();
        }
    };

    return (
        <div className='position-fixed start-0 top-0 bottom-0 end-0 pb-5 vh-100 bg-white overflow-auto' style={{ zIndex: 1 }}>
            <div className="text-center mt-5 pt-2 d-flex bg-light">
                <div className='d-flex align-items-center' onClick={backbt} style={{ height: '35px', marginTop: '-5px' }}>
                    <img className='h-100 ps-2' src={imback} />
                    <div className='ps-1'>
                        戻る
                    </div>
                </div>
                <div className='flex-grow-1 fs-5 fw-blod'>
                    {t('利用方法')}　　　
                </div>
            </div>
            <div className='px-1 w-100 bg-white overflow-auto'>
                <img className='w-100' src={imgude} />
            </div>
        </div>
    )
};

export function F__map({ onback }) {
    const { t, i18n } = useTranslation();
    const backbt = () => {
        if (onback) {
            onback();
        }
    };

    return (
        <div className='position-fixed start-0 top-0 bottom-0 end-0 pb-5 vh-100 bg-white overflow-auto' style={{ zIndex: 1 }}>
            <div className="text-center mt-5 pt-2 d-flex bg-light">
                <div className='d-flex align-items-center' onClick={backbt} style={{ height: '35px', marginTop: '-5px' }}>
                    <img className='h-100 ps-2' src={imback} />
                    <div className='ps-1'>
                        戻る
                    </div>
                </div>
                <div className='flex-grow-1 fs-5 fw-blod'>
                    {t('マップ')}　　　
                </div>
            </div>
            <div className='px-1 w-100 bg-white'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6459.159674160526!2d136.8010795!3d35.957247!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60027d3630578f4d%3A0x19d11055fbd5f94e!2z44K544OO44O844Km44Kn44O844OW44OR44O844Kv55m96bOl6auY5Y6f!5e0!3m2!1sja!2sjp!4v1703488034953!5m2!1sja!2sjp" width="100%" height="480" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                <div className='position-absolute bottom-0 bg-white'>
                    〒501-5231 岐阜県郡上市白鳥町石徹白１３３
                </div>
            </div>
        </div>
    )
}